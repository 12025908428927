import axios from "axios";
import i18n from "../configuration/i18n";

const authorizedAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Set the base URL here
});

// Automatically attach token and lang param to requests
authorizedAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Get the current language from i18n
    const lang = i18n.language || localStorage.getItem("language") || "en";

    // Ensure params exist and append the lang parameter
    config.params = { ...config.params, lang };

    return config;
  },
  (error) => Promise.reject(error)
);

export default authorizedAxios;