import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import calorieIcon from '../../../assets/icons/landing/calorie.svg';
import profileIcon from '../../../assets/icons/landing/profile.svg';
import crmIcon from '../../../assets/icons/landing/crm.svg';
import recipeIcon from '../../../assets/icons/landing/recipe.svg';
import reportIcon from '../../../assets/icons/landing/reports.svg';
import mealIcon from '../../../assets/icons/landing/meals.svg';
import styles from './BenefitsTable.module.css';

const BenefitsTable = () => {
    const items = [
        {
            icon: calorieIcon,
            title: "Calorie Calculator",
            description: "A convenient tool for accurately counting calories in your clients' diets. Allows you to quickly create personalized nutrition plans."
        },
        {
            icon: profileIcon,
            title: "Creating Profiles",
            description: "The ability to create detailed customer profiles based on their individual needs and goals. Helps to better understand the needs of each customer."
        },
        {
            icon: crmIcon,
            title: "CRM for Patient Management",
            description: "Effective management of the client database. The ability to track the progress of each client and analyze the results of work."
        },
        {
            icon: recipeIcon,
            title: "Recipe Database",
            description: "An extensive database of recipes with precise information on ingredients."
        },
        {
            icon: mealIcon,
            title: "Personalized Meal Plans",
            description: "Creating individual nutrition plans based on precise calculations and recommendations. Takes into account the characteristics of each client and helps achieve the set goals."
        },
        {
            icon: reportIcon,
            title: "Reports and Analytics",
            description: "Detailed reports and analytics on working with clients."
        }
    ];

    return (
        <section className={`py-5 text-center ${styles.section}`}>
            <Container>
                <h2 className={`${styles.sectionTitle} mb-5`}>Everything a nutritionist needs</h2>
                <Row className="g-4">
                    {items.map((item, index) => (
                        <Col key={index} xs={12} md={6}>
                            <Card className={`${styles.card} h-100 text-dark`}>
                                <Card.Body className={`${styles.cardBody} p-3`}>
                                    <Row className="align-items-start">
                                        {/* Icon Column */}
                                        <Col xs={2} className="text-center">
                                            <img
                                                src={item.icon}
                                                alt={`${item.title} icon`}
                                                className={styles.icon}
                                            />
                                        </Col>
                                        {/* Content Column */}
                                        <Col xs={10}>
                                            <Card.Title as="h5" className={`${styles.cardTitle} mb-2 text-start`}>
                                                {item.title}
                                            </Card.Title>
                                            <Card.Text className={`${styles.cardText} text-start`}>
                                                {item.description}
                                            </Card.Text>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default BenefitsTable;