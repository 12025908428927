import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Home.module.css";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../LanguageSelector/LanguageSelector";
import { useAuth } from "../../../AuthContext";
import { BsDoorOpen } from "react-icons/bs";

const Home = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleLogout = () => {
        logout();
    };

    const handleAllProfilesClick = () => {
        navigate("/profile-list");
    };

    const handleClientStatisticsClick = () => {
        // navigate("/client-statistics");
    };

    const handleNutritionFormulasClick = () => {
        // navigate("/nutrition-formulas");
    };

    const handleAllProductsClick = () => {
        navigate("/product-list");
    };

    const handleSettingsClick = () => {
        // navigate("/settings");
    };

    return (
        <div className={styles.container}>
            <div className={styles.form}>
                <div className={styles.buttons}>
                    <button
                        className={styles.button}
                        onClick={handleAllProductsClick}
                    >
                        {t("home.allProducts")}
                    </button>
                    <button
                        className={styles.button}
                        onClick={handleAllProfilesClick}
                    >
                        {t("home.allProfiles")}
                    </button>
                    <button
                        className={styles.button}
                        onClick={handleClientStatisticsClick}
                    >
                        {t("home.clientStatistics")}
                    </button>
                    <button
                        className={styles.button}
                        onClick={handleNutritionFormulasClick}
                    >
                        {t("home.nutritionFormulas")}
                    </button>
                    <button
                        className={styles.button}
                        onClick={handleSettingsClick}
                    >
                        {t("home.settings")}
                    </button>
                </div>
                <div className={`${styles['mobile-extra-buttons']}`}>
                    <div className={styles.languageSelectorWrapper}>
                        <LanguageSelector />
                    </div>
                    <BsDoorOpen
                        size={28}
                        className={styles["exit-button"]}
                        onClick={handleLogout} // Attach the logout function to the button
                    />
                </div>
            </div>
        </div>
    );
};

export default Home;