import React, { useState, useEffect, useCallback, useRef } from "react";
import styles from "./Diet.module.css";
import NutritionProgress from "../../../models/NutritionProgress";
import SearchDropdown from "./SearchDropdown/SearchDropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Datepicker.css";
import { useProfile } from "../../../ProfileContext";
import { useDispatch, useSelector } from "react-redux";
import useFetchUser from "../../../hooks/useFetchUser";
import { useTranslation } from "react-i18next";
import ExportWindow from "./ExportWindow/ExportWindow";
import { validateDigitsInput } from "../../../utils/inputUtils";
import DietPlanTable from "./DietPlanTable/DietPlanTable";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"; // For arrows
import { addDays, subDays } from "date-fns"; // For date manipulation
import {
  fetchRation,
  deleteDietItem,
  addProduct,
  updateDietItem,
} from "../../../redux/slices/rationSlice";
import debounce from "lodash/debounce";
import {
  initializeDietItems,
  calculateNutritionProgress,
} from "../../../utils/dietItemUtils";
import { formatDate } from "../../../utils/dateUtils";
import { duplicateRation } from "../../../redux/actions/rationActions";
import DateModal from "./DuplicateDateModal/DateModal";
import { createSelector } from "reselect";
import authorizedAxios from "../../../utils/authorizedAxios";

const initialNutritionProgressArray = [
  new NutritionProgress("Calories", 0, 100, "calories"),
  new NutritionProgress("Carbs", 0, 100, "carbs"),
  new NutritionProgress("Protein", 0, 100, "protein"),
  new NutritionProgress("Fat", 0, 100, "fat"),
];
const selectDietItems = (state) => state.ration.dietItems;

const initializeDietItemsSelector = createSelector(
  [selectDietItems],
  (dietItems) => initializeDietItems(dietItems)
);

const Diet = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const dietItems = useSelector(initializeDietItemsSelector);
  // const [displayTimes, setDisplayTimes] = useState([]);
  const { t, i18n } = useTranslation();
  const { selectedProfile } = useProfile();
  const user = useSelector((state) => state.profile.user); // Access user data from Redux
  const [nutritionProgressArray, setNutritionProgressArray] = useState(
    initialNutritionProgressArray
  );
  const [startDate, setStartDate] = useState(new Date());
  const [isModalOpen, setModalOpen] = useState(false);
  const [grams, setGrams] = useState(100); // Default grams

  // Use a ref to store pending updates, keyed by mealId
  const pendingUpdatesRef = useRef(new Map());
  const debounceTimeForCell = 1000;

  const handleGramsChange = (e) => {
    const inputValue = e.target.value;
    const validatedValue = validateDigitsInput(inputValue);
    setGrams(validatedValue);
  };

  const handleDelete = (id) => {
    dispatch(deleteDietItem(id));
  };


  // Optimistic update for Redux store
  const updateDietItemLocally = (mealId, updatedData) => {
    dispatch({
      type: "ration/updateDietItemLocally",
      payload: { mealId, updatedData },
    });
  };

  // Debounced function to sync all pending updates with the server
  const syncPendingUpdatesWithServer = useCallback(
    debounce(() => {
      const updates = pendingUpdatesRef.current;
      updates.forEach((updatedData, mealId) => {
        dispatch(
          updateDietItem({
            mealId,
            updatedData,
            profileId: selectedProfile.id,
            startDate: new Date(startDate),
          })
        ).unwrap().catch((error) => {
          console.error(`Failed to sync meal ${mealId}:`, error);
          // Optionally revert the update here if needed
        });
      });
      // Clear pending updates after syncing
      pendingUpdatesRef.current.clear();
    }, debounceTimeForCell),
    [dispatch, selectedProfile, startDate] // Dependencies
  );

  const handleTimeChange = (item, value) => {
    const updatedItem = { ...item };
    const [newHours, newMinutes] = value.split(":").map(Number);

    if (newHours >= 0 && newHours < 24 && newMinutes >= 0 && newMinutes < 60) {
      const newTime = new Date(updatedItem.time || startDate);
      newTime.setHours(newHours);
      newTime.setMinutes(newMinutes);
      updatedItem.time = newTime;
    } else {
      console.error("Invalid time value");
      updatedItem.time = null;
    }

    const updatedData = {
      grams: updatedItem.grams,
      productId: updatedItem.product.id,
      time: updatedItem.time ? updatedItem.time.toTimeString().slice(0, 5) : "00:00",
    };

    // Update Redux immediately (optimistic update)
    updateDietItemLocally(updatedItem.id, updatedData);

    // Store the latest update in the pending updates map
    pendingUpdatesRef.current.set(updatedItem.id, updatedData);

    // Trigger the debounced sync
    syncPendingUpdatesWithServer();
  };

  const handleChange = (index, updatedItem, name, value) => {
    updatedItem[name] = value;

    const updatedData = {
      grams: updatedItem.grams,
      productId: updatedItem.product.id,
      time: updatedItem.time ? updatedItem.time.toTimeString().slice(0, 5) : "00:00",
    };

    // Update Redux immediately (optimistic update)
    updateDietItemLocally(updatedItem.id, updatedData);

    // Store the latest update in the pending updates map
    pendingUpdatesRef.current.set(updatedItem.id, updatedData);

    // Trigger the debounced sync
    syncPendingUpdatesWithServer();
  };

  const handleProductAdd = async (product, time) => {
    if (!time) {
      time = "00:00";
    }
    const data = {
      time,
      grams,
      productId: product.id,
      date: new Date(startDate),
    };

    if (!selectedProfile) {
      return;
    }

    try {
      await dispatch(
        addProduct({
          profileId: selectedProfile.id,
          data,
        })
      );
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  const onUserFetchComplete = useCallback(() => {
    setLoading(false);
  }, []); // Empty dependency array since setLoading is stable

  useFetchUser(onUserFetchComplete);

  const handleDuplicateRation = (targetDates) => {
    // Format the startDate to keep the local date
    const sourceDate = formatDate(startDate);

    // Map over targetDates to format each date
    const targetDatesStr = targetDates.map((date) => formatDate(date));

    // Pass the profile ID, sourceDate, and formatted target dates to dispatch
    dispatch(duplicateRation(selectedProfile.id, sourceDate, targetDatesStr));
  };

  useEffect(() => {
    const summedValues = {
      Calories: 0,
      Carbs: 0,
      Protein: 0,
      Fat: 0,
    };

    dietItems.forEach((item) => {
      summedValues.Calories += Number(item.product.calories);
      summedValues.Carbs += Number(item.product.carbs);
      summedValues.Protein += Number(item.product.protein);
      summedValues.Fat += Number(item.product.fat);
    });

    const nutritionProgressItems = calculateNutritionProgress(
      summedValues,
      user,
      t
    );

    // Only update state if there is a change
    if (
      JSON.stringify(nutritionProgressArray) !==
      JSON.stringify(nutritionProgressItems)
    ) {
      setNutritionProgressArray(nutritionProgressItems);
    }
  }, [dietItems, user, t, nutritionProgressArray]);

  useEffect(() => {
    if (selectedProfile) {
      dispatch(fetchRation({ profileId: selectedProfile.id, startDate }));
    }
  }, [startDate, selectedProfile, dispatch, t]);
  // useEffect(() => {
  //   // Calculate shouldDisplayTime for each diet item when dietItems or their times change
  //   const newDisplayTimes = dietItems.map((item, index) => {
  //     return (
  //       index === 0 ||
  //       (item.time instanceof Date &&
  //         item.time.getTime() !== (dietItems[index - 1]?.time?.getTime() || 0))
  //     );
  //   });

  //   setDisplayTimes(newDisplayTimes);
  // }, [dietItems]);

  if (loading) return <div></div>;
  return (
    <div className={`${styles.container} container`}>
      <div
        className={`row row-cols-2 row-cols-sm-2 row-cols-md-2 row-cols-xl-2 row-cols-xxl-2 w-100 gx-0`}
      >
        <div
          className={`col-5 col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5 col-xxl-5 ${styles["left-side"]} `}
        >
          <div className={styles["progress-bars-table"]}>
            <div className={`row`}>
              {nutritionProgressArray.map((item) => (
                <div className={`col`} key={item.name}>
                  <div className={`${styles["progress-bar-box"]}`}>
                    <div className={`${styles["progress-title"]}`}>
                      {item.name}
                    </div>
                    <div className={`progress ${styles.progress}`}>
                      <div
                        className={`progress-bar ${styles[`progress-bar-${item.idName.toLowerCase()}`]
                          }`}
                        role="progressbar"
                        style={{
                          width: `${(item.current / item.total) * 100}%`,
                        }}
                        aria-valuenow={item.current}
                        aria-valuemin="0"
                        aria-valuemax={item.total}
                      ></div>
                    </div>
                    <div className={`${styles["progress-number"]}`}>
                      {`${parseFloat(item.current.toFixed(2))}/${item.total}`}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={styles["meal-search-table"]}>
            <div className={`row m-0`}>
              <SearchDropdown
                onProductAdd={handleProductAdd}
                grams={grams}
                onGramsChange={handleGramsChange}
              />
            </div>
          </div>
        </div>
        <div
          className={`col-7 col-xs-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 col-xxl-7 ${styles["right-side"]} `}
        >
          <div className={styles["diet-plan"]}>
            <div className={styles["diet-plan-header"]}>
              <div className={styles["diet-plan-header-text"]}>
                {t(`diet.dietPlan.header`)}
              </div>
              <div className={`${styles["date-navigation"]} `}>
                {/* Left Arrow Button */}
                <button
                  className={styles["arrow-button"]}
                  onClick={() => setStartDate(subDays(startDate, 1))} // Subtract 1 day
                >
                  <FaArrowLeft />
                </button>

                {/* DatePicker */}
                <DatePicker
                  className={styles.datepicker}
                  showIcon
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  dateFormat="dd/MM/yy"
                  locale={i18n.language}
                />

                {/* Right Arrow Button */}
                <button
                  className={styles["arrow-button"]}
                  onClick={() => setStartDate(addDays(startDate, 1))} // Add 1 day
                >
                  <FaArrowRight />
                </button>
              </div>
              <button
                onClick={() => setModalOpen(true)}
                className={`${styles["duplicate-button"]}`}
              >
                {t("diet.dietPlan.duplicate")}
              </button>
              <DateModal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                onConfirm={handleDuplicateRation}
                sourceDate={startDate}
              />
            </div>
            <DietPlanTable
              dietItems={dietItems}
              handleTimeChange={handleTimeChange}
              handleChange={handleChange}
              handleDelete={handleDelete}
            />
            <div className={`${styles["export-row"]}`}>
              <ExportWindow
                selectedProfile={selectedProfile}
                t={t}
                buttonType="fileExport"
                onExport={async (profileId, startDate, endDate) => {
                  try {
                    const response = await authorizedAxios.get(
                      `/ration/export?profileId=${profileId}&startDate=${startDate}&endDate=${endDate}`,
                      {
                        responseType: "blob",
                      }
                    );

                    const blob = new Blob([response.data], {
                      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    });

                    const downloadUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = downloadUrl;
                    link.download = `ration_${selectedProfile.name}.docx`;
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                  } catch (error) {
                    console.error("Error downloading the file:", error);
                  }
                }}
              />
              <ExportWindow
                selectedProfile={selectedProfile}
                t={t}
                buttonType="linkExport"
                onExport={(profileId, startDate, endDate) => {
                  // Open the link in a new tab with query parameters
                  const url = `/public/${profileId}?startDate=${startDate}&endDate=${endDate}`;
                  window.open(url, "_blank");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Diet;
