import React, { useState, forwardRef } from "react";
import styles from './StartGuide.module.css';

// Use forwardRef to pass the ref to a DOM element
const StartGuide = forwardRef((props, ref) => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        number: ""
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [message, setMessage] = useState(""); // Message text
    const [messageType, setMessageType] = useState(""); // New state for message type

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setMessage("");
        setMessageType(""); // Reset message type

        const API_URL = process.env.REACT_APP_API_URL;
        const requestBody = {
            name: formData.name,
            email: formData.email,
            number: formData.number || ""
        };

        try {
            const response = await fetch(`${API_URL}/auth/submit`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody),
            });

            if (response.ok) {
                setMessage("Request submitted successfully! Check your email for the guide.");
                setMessageType("success"); // Set success type
                setFormData({ name: "", email: "", number: "" });
            } else {
                setMessage("Failed to submit request. Please try again.");
                setMessageType("error"); // Set error type
            }
        } catch (error) {
            setMessage("An error occurred. Please try again later.");
            setMessageType("error"); // Set error type for network errors
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <section ref={ref} className={`${styles.startGuideWrapper} mb-4`}>
            <div className={styles.startGuide}>
                <div className={styles.formContainer}>
                    <h2 className={styles.title}>Speed up and improve your work</h2>
                    <p className={styles.subtitle}>
                        Our platform helps you attract more clients by streamlining diet planning — no extra tools needed!
                    </p>
                    <form onSubmit={handleSubmit} className={styles.form}>
                        <div className={styles.formGroup}>
                            <label htmlFor="email" className={styles.label}>Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="E-mail"
                                required
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="name" className={styles.label}>Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Helena Monaco"
                                required
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="number" className={styles.label}>Phone (optional)</label>
                            <input
                                type="tel"
                                id="number"
                                name="number"
                                value={formData.number}
                                onChange={handleChange}
                                placeholder="+123 456 7890"
                                className={styles.input}
                            />
                        </div>
                        <button type="submit" disabled={isSubmitting} className={styles.submitButton}>
                            {isSubmitting ? "Submitting..." : "FREE GUIDE TO START"}
                        </button>
                        <p className={styles.footerText}>Start using our tools today!</p>
                        <p
                            className={`${styles.message} ${messageType === "error" ? styles['error-message'] :
                                    messageType === "success" ? styles['success-message'] : ''
                                }`}
                        >
                            {message}
                        </p>
                    </form>
                </div>
            </div>
        </section>
    );
});

export default StartGuide;