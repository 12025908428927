import React, { useState, useEffect, useRef } from "react";
import styles from "./SearchDropdown.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useProfile } from "../../../../ProfileContext";
import {
  fetchSearchProducts,
  resetSearch,
} from "../../../../redux/slices/productSearchSlice";
import GramsInput from './GramsInput/GramsInput'; 

const SearchDropdown = ({ onProductAdd, grams, onGramsChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { searchResults, hasMore, loading } = useSelector(
    (state) => state.productSearch
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [time, setTime] = useState("10:00");
  const [searchPageSize] = useState(50);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { selectedProfile } = useProfile();
  const searchDropdownRef = useRef(null);

  const handleSearchChange = (e) => {
    const inputValue = e.target.value;
    setSearchTerm(inputValue);
    setIsMenuOpen(true);

    if (inputValue.trim() === "") {
      dispatch(resetSearch());
    } else {
      dispatch(resetSearch());
      dispatch(
        fetchSearchProducts({
          searchTerm: inputValue,
          page: 0,
          size: searchPageSize,
        })
      );
    }
  };

  const handleSearchFocus = () => {
    if (searchTerm.trim() !== "" && searchResults.length > 0) {
      setIsMenuOpen(true); // Reopen the menu if there’s a search term and results
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
  };

  const handleProductSelect = (product) => {
    // No action needed here for closing/resetting
  };

  const handleProductAddClick = (product) => {
    onProductAdd(product, time);
  };

  const fetchMoreResults = () => {
    if (hasMore && searchTerm.trim()) {
      dispatch(
        fetchSearchProducts({
          searchTerm,
          page: Math.ceil(searchResults.length / searchPageSize),
          size: searchPageSize,
        })
      );
    }
  };

  // Handle clicks outside the dropdown to close the menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchDropdownRef.current &&
        !searchDropdownRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    return () => {
      setSearchTerm("");
      dispatch(resetSearch());
    };
  }, [dispatch, selectedProfile]);

  return (
    <div className={`${styles.searchDropdown}`} ref={searchDropdownRef}>
      <form onSubmit={handleSearchSubmit}>
        <div className={`${styles.searchInputContainer}`}>
          <FontAwesomeIcon icon={faMagnifyingGlass} className={styles.icon} />
          <div className={`${styles["search-input-wrapper"]}`}>
            <input
              type="text"
              placeholder={t("productSearchDropdown.searchPlaceholder")}
              value={searchTerm}
              onChange={handleSearchChange}
              onFocus={handleSearchFocus} // Add focus handler
              className={styles.searchInput}
            />
          </div>
          <GramsInput
            grams={grams}
            onGramsChange={onGramsChange}
            time={time}
            setTime={setTime}
          />
        </div>
      </form>
      {isMenuOpen && searchResults.length > 0 && (
        <InfiniteScroll
          dataLength={searchResults.length}
          next={fetchMoreResults}
          hasMore={hasMore}
          loader={loading && <p>Loading...</p>}
          endMessage={<div></div>}
          height={300}
          scrollThreshold={0.99}
          className={`${styles["diet-items-scrollable-container"]}`}
        >
          <ul className={styles.dropdownMenu}>
            {searchResults.map((product) => (
              <li
                key={product.id}
                className={styles.dropdownItem}
                onClick={() => {
                  handleProductSelect(product);
                  handleProductAddClick(product);
                }}
              >
                <div className={styles.dropdownItemContent}>
                  <div className={styles.dropdownItemInfo} title={product.name}>
                    <div
                      className={`${styles.dropdownItemLabel} text-truncate`}
                    >
                      {product.name}
                    </div>
                    <div className={`${styles.dropdownItemText}`}>
                      {t("productSearchDropdown.kcal")}: {product.calories}{" "}
                      {t("productSearchDropdown.protein")}: {product.protein}{" "}
                      {t("productSearchDropdown.carbs")}: {product.carbs}{" "}
                      {t("productSearchDropdown.fat")}: {product.fat}
                    </div>
                  </div>
                  <div className={styles.dropdownButtons}>
                    <button className={`${styles.customButton}`}>
                      <FontAwesomeIcon
                        icon={faPlus}
                        className={styles.icon}
                        size="lg"
                      />
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <div className={styles.hackyOffPageElement}></div>
        </InfiniteScroll>
      )}
    </div>
  );
};

export default SearchDropdown;