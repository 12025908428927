import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./Header.module.css";
import logo from "../../../assets/icons/diet-dash.svg";
import SearchDropdown from "./ProfileSearchDropdown/ProfileSearchDropdown";
import { BsDoorOpen } from "react-icons/bs";
import { useProfile } from "../../../ProfileContext";
import { useAuth } from "../../../AuthContext"; // Import AuthContext
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../LanguageSelector/LanguageSelector"; // Import LanguageSelector

const Header = () => {
  const { t } = useTranslation(); // Hook to get translation function
  const { profiles } = useProfile();
  const { logout } = useAuth(); // Use the logout function from useAuth hook
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchResults, setSearchResults] = useState(profiles);
  const navigate = useNavigate();
  const location = useLocation();

  const getCurrentPage = () => {
    const path = location.pathname;

    // Check if path matches exactly '/profile' or '/profile/:id' (UUID pattern)
    if (/^\/profile(\/[a-f0-9\-]+)?$/.test(path)) {
      return "profile";
    }

    // Check other routes
    switch (path) {
      case "/home":
        return "home";
      case "/diet":
        return "diet";
      case "/product-list":
        return "productList";
      default:
        return "";
    }
  };

  const currentPage = getCurrentPage();

  const handleDropdownVisibility = (isOpen) => {
    setDropdownOpen(isOpen);
  };

  const handleClick = (page) => {
    navigate(`/${page.toLowerCase()}`);
  };

  const handleLogout = () => {
    logout(); // Use the logout function from useAuth hook
  };

  const handleAddNewProfile = () => {
    navigate("/add-profile");
  };

  return (
    <header className={styles.header}>
      <div className={`${styles["left-group"]}`}>
        <div className={styles.logo}>
          <img src={logo} alt="Logo" height={48} />
        </div>
        <div className={styles["header-buttons-group-one"]}>
          <button
            className={`${styles.button} ${currentPage === "home" ? styles["button-active"] : ""
              }`}
            onClick={() => handleClick("home")}
          >
            {t(`header.home`)}
          </button>
          <LanguageSelector />
        </div>
      </div>
      <div className={`${styles["right-group"]}`}>
        <div className={styles["header-buttons"]}>
          <button
            className={`${styles.button} ${currentPage === "profile" ? styles["button-active"] : ""
              }`}
            onClick={() => handleClick("profile")}
          >
            {t(`header.profile`)}
          </button>
          <button
            className={`${styles.button} ${currentPage === "diet" ? styles["button-active"] : ""
              }`}
            onClick={() => handleClick("diet")}
          >
            {t(`header.diet`)}
          </button>
          <button
            className={`${styles.button} ${currentPage === "productList" ? styles["button-active"] : ""
              }`}
            onClick={() => handleClick("product-list")}
          >
            {t(`header.productList`)}
          </button>
        </div>
        <div className={styles["header-buttons-last"]}>
          <div
            className={`${styles["profile-selection-form"]} ${dropdownOpen && searchResults && searchResults.length > 0
              ? styles["open"]
              : styles["closed"]
              }
          `}
          >
            <SearchDropdown
              onDropdownVisibilityChange={handleDropdownVisibility}
              searchResults={searchResults}
              setSearchResults={setSearchResults}
              onAddNewProfile={handleAddNewProfile}
            />
          </div>
          <BsDoorOpen
            size={28}
            className={styles["exit-button"]}
            onClick={handleLogout} // Attach the logout function to the button
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
